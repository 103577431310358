@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;
  background-color: #F8FAFC;
}

.app {
  display: flex;
  position: relative;
}

