.Container {
  overflow: hidden;


  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

  /* For webkit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 0em;
}


/* For Microsoft Edge and Internet Explorer */
.Container::-ms-scrollbar {
  width: 0em; /* Adjust the width to your preference */
}

